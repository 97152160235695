.historyTitle {
    margin-bottom: 0rem;
}
.historySubTitle {
    margin-top: 0rem;
    font-size: 1rem;
}
.historyWrapper {
    width: 100%;
    height: 100%;
    /* background-color: #f5f5f5; */
    padding: 2rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    list-style: none;
}
.historyItem {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    word-break: break-all;
    margin-bottom: 1rem;
}
.historyItem p {
    margin-right: 1rem;
    font-weight: bold;
}
.historyItem:hover p {
    text-decoration: underline;
}
.historyItem + .historyItem {
    border-top: 0.2rem solid #f5f5f5;
}
.clearHistoryBtn {
    margin-left: auto;
    border: 0;
    background-color: #fff;
    color: #222;
    display: block;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.clearHistoryBtn:hover {
    background-color: #f5f5f5;
}
@media screen and (min-width: 768px) {
    .historyItem {
        flex-direction: row;
        margin-bottom: 0;
        align-items: center;
    }
}