html {
  font-size: 16px;
}
body {
  margin: auto;
  max-width: 761px;
  padding: 0rem 1rem;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  box-sizing: border-box;
  word-break: break-all;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.7rem;
}
h3 {
  font-size: 1.5rem;
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
}
button, a {
  cursor: pointer;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.homeHeaderText, .homeHeaderSubText {
  text-align: center;
}
.homeHeaderText {
  margin-bottom: 0rem;

}
.homeHeaderSubText {
  margin-top: 0.5rem;
  font-size: 1rem;
  margin-bottom: 2rem;
}
.shimmerLoader {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 600px 250px; 
  display: inline-block;
  position: relative; 
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}
.smallTextLoader {
  min-width: 100px;
  height: 1.5rem;
  border-radius: 60px;
}
.extraSmallTextLoader {
  min-width: 80px;
  height: 1.2rem;
  border-radius: 60px;
  margin-top: 0.5rem;
}
@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}