.searchForm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  gap: 1rem;
}
.searchInputWrapper{
    position: relative;
}
.searchFormInput {
    border: 0.2rem solid #f5f5f5;
    width: 100%;
}
.searchSubmitButton {
    background-color: #222;
    color: #fff;
    border: 0;
}
.searchClearButton {
    border: 0;
    position: absolute;
    right: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    font-size: 1rem;
}

.searchFormInput, .searchSubmitButton {
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* @media screen and (min-width: 450px) {
    .searchFormInput {
        width: auto;
    }
    .searchForm {
        justify-content: center;
    }
} */