.headingSpan {
    background-color: #f5f5f5;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}
.userDetailsHeading {
    margin-bottom: 1rem;
    font-size: 2rem;
}
.userDetailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  margin: 1rem 0;
  padding: 0.5rem;
}
.userImage {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin: 1rem auto;
    object-fit: cover;
    object-position: center;
}

.userDetails {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0;
    padding: 0;
}
.userDetailsItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.userDetailsItem p {
    max-width: 50%;
}
.userDetailsItem + .userDetailsItem {
    border-top: 0.2rem solid #f5f5f5;
}
.userRepos {
    width: 100%;
}
.repoContainer {
   display: flex; 
   margin-bottom: 1rem;
   flex-wrap: wrap;
   align-items: center;
   justify-content: start;
   gap: 0.3rem;
}
.repoItem {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 100%;
    padding: 1rem;
    width: 100%;
    border: rgba(0, 0, 0, 0.15) 0.1rem solid;
    border-radius: 0.2rem;
}
.repoLinkContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.repoItemLink {
    font-weight: bold;
    margin-right: 0.5rem;
}
.repoPill {
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    background-color: #f5f5f5;
    min-width: fit-content
}
.repoMetaData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .userDetailsWrapper {
        padding: 2rem 1.5rem;
    }
    .repoItem {
        width: 49%;
    }
}